import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import layoutContext from 'components/layoutContext'
import SEO from 'components/seo'
import renderAst from 'components/rehyped'

const Default = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: { title, lang, path, noindex, canonicalUrl, description },
      htmlAst
    }
  } = data
  const { setCult } = useContext(layoutContext)
  setCult(lang)
  return (
    <>
      <SEO
        description={description}
        noindex={noindex}
        title={title}
        path={path}
        lang={lang}
        canonicalUrl={canonicalUrl}
        type='page'
        rating={
          data.markdownRemark.frontmatter.rating
            ? data.markdownRemark.frontmatter.rating
            : 4.6
        }
      />
      {renderAst(htmlAst)}
    </>
  )
}

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        description
        title
        path
        tags
        lang
        rating
        noindex
        canonicalUrl
      }
    }
  }
`

Default.propTypes = {
  data: PropTypes.object
}

export default Default
